import * as React from 'react';
import { DocumentViewerContainer } from '../../common/document-viewer/DocumentViewerContainer';
import { IssuerDetailMap, StatusCode } from '../../bill-of-lading/common';
import { LevelOfChanges } from '../../common/document-viewer/FileSplitter';
import { DocumentAction } from './index';
import { ReclassifyDocument } from './ReclassifyDocument';
import { APITeam } from '../../../api/teams';
import { APIUser } from '../../../api/comment';
import { Notification } from '../../common/Notification';
import {
  APIDocCoordinate,
  APIDocumentContainer,
  APITableDefinitions,
  CoordinatesFilter,
  DocumentContainerAPI,
  filterCoordinates
} from '../../../api/documentContainer';
import { OptionValue } from 'react-selectize';
import { APIValidationResult } from '../../../api/validationResults';
import { APIIssueCode } from '../../../api/issueCode';
import { CreditorChangedParams } from '../ApInvoiceSection';
import { DocumentViewerContext } from '../../DocumentViewerContext';
import { SearchFilter } from '../../common/document-list';
import { APIPermissionProfile } from '../../../api/permissionProfiles';
import { filterApCoordinates } from './helpers';

interface Props {
  teams: APITeam[];
  users: APIUser[];
  currencyOptions: OptionValue[];
  addresseeOptions: OptionValue[];
  issueCodes: APIIssueCode[];
  permissionProfile: APIPermissionProfile | null;
  setNotification: (notification: Notification | null) => void;
  selectedDocument: APIDocumentContainer | undefined;
  selectedValidationResult: APIValidationResult | null;
  documentTypesOptions: OptionValue[];
  selectNextDocument: () => void;
  reLoadSelectedDocument: () => Promise<void>;
  creditorChanged: (params: CreditorChangedParams) => void;
  setInvoiceResubmitted: (invoiceId: number) => void;
  issuerDetails: IssuerDetailMap;
  currentSearchFilter: SearchFilter;
  showMisclassifiedDocuments: boolean;
  onFileSplitFinished: (levelOfChanges: LevelOfChanges, affectedDocument: APIDocumentContainer) => void;
  fetchValidationResultData: (validationResultId: number, invoiceId: number) => void;
  updateValidationResultForInvoice: (invoiceId: number, validationResult: APIValidationResult) => void;
  showCargoWiseSettings: boolean;
  checkPermissionProfileAccess: (value: keyof APIPermissionProfile) => boolean;
  changesDisabled: boolean;
}

export const SelectedDocumentWrapper: React.FC<Props> = (props) => {
  const [loadedFileUnqId, setLoadedFileUnqId] = React.useState<string | undefined>(undefined);

  const [focusedCoordinates, setFocusedCoordinates] = React.useState<APIDocCoordinate[]>([]);
  const [allCoordinates, setAllCoordinates] = React.useState<{ [id: string]: APIDocCoordinate[] }>({});
  const [coordinates, setCoordinates] = React.useState<APIDocCoordinate[]>([]);
  const [allTableCoordinates, setAllTableCoordinates] = React.useState<{ [id: string]: APITableDefinitions }>({});
  const [tableCoordinates, setTableCoordinates] = React.useState<APITableDefinitions>({});

  React.useEffect(() => {
    setAllCoordinates({});
    setCoordinates([]);

    let didCancel = false;
    const invoiceData = props.selectedValidationResult?.supplierInvoice;

    if (!invoiceData) return;

    DocumentContainerAPI.fetchCoordinates(invoiceData.documentId).then((loadedCoordinates) => {
      if (didCancel) {
        console.log('Fetched coordinates data no longer needed due to another fetch request for a different document');
        return;
      }

      const filteredCoordinates = filterApCoordinates(loadedCoordinates, invoiceData.clusters, invoiceData.lineItems, invoiceData.document.emailAccount.cnValidation);

      setCoordinates(filteredCoordinates);

      if (filteredCoordinates && filteredCoordinates.length) {
        setAllCoordinates({ [filteredCoordinates[0].documentId]: filteredCoordinates });
        setCoordinates(filteredCoordinates);
      }
    });

    return () => { didCancel = true; };
  }, [props.selectedValidationResult?.supplierInvoice.id]);

  const onRemoveCoordinates = React.useCallback((coordinatesFilter: CoordinatesFilter) => {
    if (!Object.keys(coordinatesFilter).length) {
      return;
    }

    DocumentContainerAPI.removeCoordinates(coordinatesFilter);
    setAllCoordinates((prevValue) => filterCoordinates(prevValue, coordinatesFilter));
  }, []);

  const fileSplitterSettings = React.useMemo(() => ({
    hideFileSplitterButton: props.selectedValidationResult?.supplierInvoice.document.documentGroup.status !== StatusCode.Unposted || props.changesDisabled,
    isApSection: true
  }), [props.selectedValidationResult?.supplierInvoice.document.documentGroup.status]);

  return (
    <DocumentViewerContext.Provider value={{
      focusedCoordinates,
      setFocusedCoordinates,
      removeCoordinates: onRemoveCoordinates,
      setCoordinates,
      setAllCoordinates,
      coordinates,
      allCoordinates,
      allTableCoordinates,
      tableCoordinates
    }}>
      <DocumentViewerContainer
        document={props.selectedDocument}
        setNotification={props.setNotification}
        documentTypesOptions={props.documentTypesOptions}
        setFileUnqId={setLoadedFileUnqId}
        fileUnqId={loadedFileUnqId}
        fileSplitterSettings={fileSplitterSettings}
        onFileSplitFinished={props.onFileSplitFinished}
      />
      {props.selectedDocument?.supplierInvoice || (!props.selectedDocument && !props.showMisclassifiedDocuments) ? (
        <DocumentAction
          changesDisabled={props.changesDisabled}
          data={props.selectedValidationResult}
          teams={props.teams}
          users={props.users}
          setNotification={props.setNotification}
          currentSearchFilter={props.currentSearchFilter}
          selectNextDocument={props.selectNextDocument}
          setValidationResultData={props.fetchValidationResultData}
          updateValidationResultForInvoice={props.updateValidationResultForInvoice}
          setInvoiceResubmitted={props.setInvoiceResubmitted}
          permissionProfile={props.permissionProfile}
          issuerDetails={props.issuerDetails}
          creditorChanged={props.creditorChanged}
          removeCoordinates={onRemoveCoordinates}
          reloadData={props.reLoadSelectedDocument}
          currencyOptions={props.currencyOptions}
          addresseeOptions={props.addresseeOptions}
          issueCodes={props.issueCodes}
          showCargoWiseSettings={props.showCargoWiseSettings}
          checkPermissionProfileAccess={props.checkPermissionProfileAccess}
        />
      ) : (
        <ReclassifyDocument
          teams={props.teams}
          users={props.users}
          document={props.selectedDocument}
          selectNextDocument={props.selectNextDocument}
          setNotification={props.setNotification}
          currentSearchFilter={props.currentSearchFilter}
        />
      )}
    </DocumentViewerContext.Provider>
  )
}
