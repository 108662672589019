import * as React from 'react';
import { File } from './File';
import { APIDocumentGroupSearchDetails } from '../../../api/documentGroup';
import { fwdOrFncPostingBehaviour, IssuerDetailMap, PostingBehaviour, StatusCode } from '../common';
import { APIDocumentContainer, APIDocumentType, DocumentType } from '../../../api/documentContainer';
import { APIEmailBasic } from '../../../api/email';
import { ActionModalType } from '../../common/DocumentActionModal';
import { JobTile, JobTileType } from './JobTile';
import { ValidationType } from '../../../api/emailAccount';
import { trimRecordId } from '../../../api/issuer';
import { BeforeCapture, DragDropContext, DropResult } from 'react-beautiful-dnd';
import { HierarchyChange } from './DetailFileActions';
import { AddItemBtn } from '../../common/AddItemBtn';
import { ConfirmModal } from '../../common/ConfirmModal';

import './file-sorter.scss';
import { OptionValue } from 'react-selectize';

interface Props {
  details: APIDocumentGroupSearchDetails | null;
  issuerDetails: IssuerDetailMap;
  documentTypes: APIDocumentType[];
  activeDocument: APIDocumentContainer | APIEmailBasic | undefined;
  actionType: ActionModalType;
  newGroup: APIDocumentContainer[];
  displayActions?: boolean;
  selectedTypeUpdates?: DocumentTypesToUpdate[];
  isDragDisabled: boolean;
  hierarchyChanges: HierarchyChange[];
  editMode?: boolean;
  setActiveDocument: (document: APIDocumentContainer | APIEmailBasic | undefined, scroll?: boolean) => void;
  setDocTypesToUpdate?: (value: DocumentTypesToUpdate[]) => void;
  setSelectedFiles?: (selectedFiles: number[]) => void;
  setHierarchyChanges?: (changes: React.SetStateAction<HierarchyChange[]>) => void;
  setDetails?: (changes: React.SetStateAction<APIDocumentGroupSearchDetails | null>) => void;
}

export interface FileElement {
  id?: number;
  documentId: number;
  type: DocumentType;
  element: JSX.Element;
  isValid?: boolean;
}

export interface DocumentTypesToUpdate {
  id: number;
  documentId: number;
  oldType: DocumentType;
  newType: DocumentType;
}

export interface JobTile {
  files: FileElement[],
  type: JobTileType,
  jobRef?: string,
  info1?: string,
  info2?: string,
  topDocumentId: number;
  isPlaceholder?: boolean;
}

export const placeholderTypes = [DocumentType.PlaceholderBJob, DocumentType.PlaceholderShipment, DocumentType.PlaceholderConsol];

const sortOrder: { [k: number]: number } = {
  [DocumentType.PlaceholderConsol]: 1,
  [DocumentType.PlaceholderBJob]: 1,
  [DocumentType.MasterBillOfLading]: 2,
  [DocumentType.PlaceholderShipment]: 3,
  [DocumentType.HouseBillOfLading]: 4,
  [DocumentType.CommercialInvoice]: 5,
  [DocumentType.PackingList]: 6,
  [DocumentType.CertificateOfOrigin]: 7,
  [DocumentType.DeliveryOrder]: 8,
  [DocumentType.PackingDeclaration]: 9,
  [DocumentType.ArrivalNotice]: 10,
  [DocumentType.SupplierInvoice]: 11,
  [DocumentType.OverheadInvoice]: 11,
  [DocumentType.Undefined]: 12,
  [DocumentType.VendorQuote]: 13,
  [DocumentType.ISFDocument]: 14,
  [DocumentType.ProofOfDelivery]: 15,
  [DocumentType.HealthCertificate]: 16,
  [DocumentType.ManufacturesDeclaration]: 17,
  [DocumentType.ForwardersCargoReceipt]: 18,
  [DocumentType.VendorPaidOriginChargeSummary]: 19,
  [DocumentType.AgentsInvoice]: 20,
  [DocumentType.InterimFootwearForm]: 21,
  [DocumentType.FirstSaleInvoice]: 22,
  [DocumentType.GeneralConformityCertificate]: 23,
  [DocumentType.VendorInvoiceApproval]: 24,
  [DocumentType.MiscellaneousCustomsDocument]: 25,
  [DocumentType.WharfGateIn]: 26,
  [DocumentType.UKN]: 99,
  [DocumentType.EmailBody]: 100,
  [DocumentType.MultiDoc]: 1000,
}

export const getFlatFiles = (documents: APIDocumentContainer[]): APIDocumentContainer[] => {
  const flat: APIDocumentContainer[] = [];
  const ids: number[] = [];
  documents.forEach((d) => {
    if (!ids.find((id) => d.id === id)) {
      flat.push(d);
      ids.push(d.id);
    }
    if (d.children) {
      d.children.forEach((d) => {
        if (!ids.find((id) => d.id === id)) {
          flat.push(d);
          ids.push(d.id);
        }
        if (d.children) {
          d.children.forEach((d) => {
            if (!ids.find((id) => d.id === id)) {
              flat.push(d);
              ids.push(d.id);
            }
          });
        }
      });
    }
  });
  return flat;
}

export const findTopFile = (documents: APIDocumentContainer[] | undefined) => {
  if (documents) {
    let topFile = documents.find((document) => {
      if (document.children) return document.children && document.children.length > 0;
    });
    if (topFile) return topFile;
    return documents.find((doc) => doc.documentType === DocumentType.MasterBillOfLading ||
      doc.documentType === DocumentType.PlaceholderConsol);
  }
  return null;
}

// Used to decide the order which files are shown in the sorter
export const sortByTypes = (a: APIDocumentContainer, b: APIDocumentContainer) => {
  return sortOrder[a.documentType] - sortOrder[b.documentType];
}

function getDocumentPriority(type: DocumentType) {
  switch (type) {
    case DocumentType.MasterBillOfLading: return 1;
    case DocumentType.HouseBillOfLading: return 2;
    case DocumentType.CommercialInvoice: return 3;
    case DocumentType.PackingList: return 4;
    case DocumentType.Undefined: return 100;
    case DocumentType.EmailBody: return 101;
    default:
      return 50; // A value in the middle
      break;
  }
}

// Used to decide which document should be chosen initially
function sortByInitialPriority(a: APIDocumentContainer, b: APIDocumentContainer) {
  return getDocumentPriority(a.documentType) - getDocumentPriority(b.documentType);
}

export const buildNewHierarchy = (documents: APIDocumentContainer[], change: HierarchyChange, flatDocuments: APIDocumentContainer[]) => {
  const builtHierarchy: APIDocumentContainer[] = [];

  documents = documents.sort(sortByTypes);

  // Loop through the documents
  documents?.forEach((document, grandparentIndex) => {
    // If the document, is not the document we're moving
    if (change.childId !== document.id) {
      builtHierarchy.push(document);
    }
    // If the parent of the document we're moving, is this document
    if (change.documentId === document.id) {
      // Find the document we're moving
      const childDocument = flatDocuments.find((d) => d.id === change.childId) as APIDocumentContainer;

      // Remove its children
      const strippedChildDocument = childDocument;
      strippedChildDocument.children = [];

      // Push it to the grandparent's children
      if (!builtHierarchy[grandparentIndex]?.children) {
        if (!builtHierarchy[grandparentIndex]) {
          builtHierarchy[builtHierarchy.length - 1].children = [strippedChildDocument];
        } else {
          builtHierarchy[grandparentIndex].children = [strippedChildDocument];
        }
      } else {
        builtHierarchy[grandparentIndex].children.push(strippedChildDocument);
      }
    }

    // If the current document has children
    if (document.children) {
      // Iterate through them
      document.children.forEach((document, parentIndex) => {
        // If this document, is the parent of the document we're moving
        if (change.documentId === document.id) {
          // Find the current latest index of the grandparent's children

          // Find the document to move
          const childDocument = flatDocuments.find((d) => d.id === change.childId) as APIDocumentContainer;

          // Remove the document's children
          const strippedChildDocument = childDocument;
          strippedChildDocument.children = [];

          // Add it to the grandfather's latest child
          if (!builtHierarchy[grandparentIndex]?.children[parentIndex]?.children) {
            if (!builtHierarchy[grandparentIndex].children[parentIndex]) {
              const length = builtHierarchy[grandparentIndex].children.length - 1;
              builtHierarchy[grandparentIndex].children[length].children = [strippedChildDocument];
            } else {
              builtHierarchy[grandparentIndex].children[parentIndex].children = [strippedChildDocument];
            }
          } else {
            builtHierarchy[grandparentIndex].children[parentIndex].children.push(strippedChildDocument);
          }
        }
        // If the document we're inspecting is not the child we're moving
        if (change.childId !== document.id) {
          const strippedDocument = document;
          // Remove all children that aren't being moved
          const unmovedChildren = strippedDocument?.children?.filter((d) => d.id !== change.childId);

          strippedDocument.children = unmovedChildren ? unmovedChildren : [];

          // If the document, matches the change's parent, add this back.
          if (document.id === change.documentId) {
            // Find the document to move
            const childDocument = flatDocuments.find((d) => d.id === change.childId) as APIDocumentContainer;

            // Remove the document's children
            const strippedChildDocument = childDocument;
            strippedChildDocument.children = [];

            unmovedChildren.push(strippedChildDocument);
          }

          // Add the document we're inspecting
          if (!builtHierarchy[grandparentIndex]?.children) {
            if (!builtHierarchy[grandparentIndex]) {
              builtHierarchy[builtHierarchy.length - 1].children = [strippedDocument];
            } else {
              builtHierarchy[grandparentIndex].children = [strippedDocument];
            }
          } else {
            if (!builtHierarchy[grandparentIndex].children.some((d) => d.id === document.id))
              builtHierarchy[grandparentIndex].children.push(strippedDocument);
          }
        }

        // If the document we're inspecting has children
        const parent = document.id;
        if (document.children) {
          document.children.forEach((document) => {

            if (change.childId !== document.id) {
              const strippedDocument = document;
              strippedDocument.children = [];

              // Add it
              if (!builtHierarchy[grandparentIndex]?.children[parentIndex]?.children) {
                if (!builtHierarchy[grandparentIndex].children[parentIndex]) {
                  const length = builtHierarchy[grandparentIndex].children.length - 1;
                  builtHierarchy[grandparentIndex].children[length].children = [strippedDocument];
                } else {
                  builtHierarchy[grandparentIndex].children[parentIndex].children = [strippedDocument];
                }
              } else {
                if (!builtHierarchy[grandparentIndex].children[parentIndex].children.some((d) => d.id === document.id))
                  builtHierarchy[grandparentIndex].children[parentIndex].children.push(strippedDocument);
              }
            }
          });
        }
      });
    }
  });
  return builtHierarchy;
}

export const FileSorter: React.FC<Props> = React.memo((props) => {
  const newItemsHolderRef = React.useRef<HTMLDivElement | null>(null);
  const [flatFiles, setFlatFiles] = React.useState<APIDocumentContainer[]>(getFlatFiles(props.details?.documents || []));

  const [companyDocTypes, setCompanyDocTypes] = React.useState<{ [k: number]: string }>({});
  const [newGroupFiles, setNewGroupFiles] = React.useState<FileElement[]>([]);
  const [selectedFiles, setSelectedFiles] = React.useState<number[]>([]);
  const [isDragging, setIsDragging] = React.useState<boolean>(false);
  const [jobTiles, setJobTiles] = React.useState<JobTile[]>([]);
  const [deleteShipment, setDeleteShipment] = React.useState<number | null>(null);

  const others: APIDocumentContainer[] = [];

  let startY = 0;
  let startHeight = 0;

  const documentTypesOptions: OptionValue[] = React.useMemo(() => {
    return props.documentTypes.filter((dt) => dt.id !== DocumentType.EmailBody)
      .map((type) => {
        return {
          label: type.displayName ? type.cwCode + ' - ' + type.displayName : '',
          value: type.id,
        };
      });
  }, [props.documentTypes]);

  const getIssuer = (info?: string) => {
    const enriched = info ? {
      value: info, label: props.issuerDetails[trimRecordId(info)]?.name ||
        props.issuerDetails[trimRecordId(info)]?.cgwCode || ''
    } : undefined
    return enriched;
  }

  const showDetails = (
    documentType: DocumentType,
    validationType: ValidationType | undefined,
    postingBehaviour: PostingBehaviour | undefined
  ): boolean => {
    if (![DocumentType.HouseBillOfLading,
    DocumentType.MasterBillOfLading,
    DocumentType.CommercialInvoice].includes(documentType)) {
      return false;
    }
    switch (validationType) {
      case ValidationType.ClearanceAndForwarding:
      case ValidationType.Forwarding:
        if ([DocumentType.CommercialInvoice].includes(documentType)) {
          return false;
        } else {
          return true;
        }
      case ValidationType.Clearance:
        if (postingBehaviour !== PostingBehaviour.NewBJob && documentType === DocumentType.HouseBillOfLading) {
          return false;
        } else {
          return true;
        }
      default:
        return true;
    }
  }

  const initDrag = (e: any) => {
    if (newItemsHolderRef.current && document.defaultView) {
      document.documentElement.addEventListener("mousemove", doDrag, false);
      document.documentElement.addEventListener("mouseup", stopDrag, false);
      startY = e.clientY;
      startHeight = parseInt(document.defaultView.getComputedStyle(newItemsHolderRef.current).height, 10);

      let draggableDivs = Array.from(document.getElementsByClassName("width-height-draggable"));
      draggableDivs.forEach((element) => {
        element.classList.add("no-select");
      });

      let files = Array.from(document.getElementsByClassName("file"));
      files.forEach((element) => {
        element.classList.add("no-event");
      });
    }
  }

  const doDrag = (e: any) => {
    if (newItemsHolderRef.current && document.defaultView) {
      newItemsHolderRef.current.style.height = startHeight + (e.clientY - startY) + "px";
    }

    let files = Array.from(document.getElementsByClassName("file"));
    files.forEach((element) => {
      element.classList.add("no-event");
    });
  }

  const stopDrag = () => {
    if (newItemsHolderRef.current) {
      document.documentElement.removeEventListener("mousemove", doDrag, false);
      document.documentElement.removeEventListener("mouseup", stopDrag, false);

      let draggableDivs = Array.from(document.getElementsByClassName("width-height-draggable"));
      draggableDivs.forEach((element) => {
        element.classList.remove("no-select");
      });

      let files = Array.from(document.getElementsByClassName("file"));
      files.forEach((element) => {
        element.classList.remove("no-event");
      });
    }
  }


  const onDragEnd = (result: DropResult) => {
    setIsDragging(false);
    const { source, destination } = result;

    // dropped outside the list
    if (!destination || source.droppableId === destination.droppableId) {
      return;
    }

    if (source.droppableId !== destination.droppableId) {
      const sourceIndex = jobTiles[parseInt(source.droppableId)].isPlaceholder ? source.index - 1 : source.index;
      let child = jobTiles[parseInt(source.droppableId)].files[sourceIndex];
      const change: HierarchyChange = {
        documentId: jobTiles[parseInt(destination.droppableId)].topDocumentId,
        childId: child.documentId,
      };

      const detailsCopy = props.details;
      if (detailsCopy?.documents && props.setDetails) {
        detailsCopy.documents = buildNewHierarchy(detailsCopy.documents, change, flatFiles);
        props.setDetails(detailsCopy);
        buildJobTiles(detailsCopy);
      }

      if (props.setHierarchyChanges) {
        // Setting hierarchy changes
        props.setHierarchyChanges((hierarchyChanges) => [...hierarchyChanges, change]);
      }
    }
  }

  React.useEffect(() => {
    props.setSelectedFiles !== undefined && props.setSelectedFiles(selectedFiles);
  }, [selectedFiles]);

  React.useEffect(() => {
    if (props.documentTypes.length > 0) {
      const customDocTypes = Object.assign({ [DocumentType.EML]: 'EML' }, ...props.documentTypes.map((type) => ({ [type.id]: type.cwCode })));
      setCompanyDocTypes(customDocTypes);
    }
  }, [props.documentTypes])

  const onSelect = (id: number, selected: boolean) => {
    if (selected) {
      setSelectedFiles((selected) => [...selected, id]);
    } else {
      setSelectedFiles((selected) => selected.filter((file) => file !== id));
    }
  };

  const buildFileElement = (document: APIDocumentContainer, draggableIndex?: number, noDrag?: boolean): {
    element: JSX.Element
  } => {
    let hasPostedConsol = false;
    const displayEndActions = props.actionType === ActionModalType.SplitMode;
    const displaySelect = newGroupFiles.length <= 0;

    if (document.billOfLading) {
      hasPostedConsol = props.details?.status === StatusCode.Posted;

      const shipperDetail = props.issuerDetails[document.billOfLading.shipperCgwRecordId || ''];
      const consigneeDetail = props.issuerDetails[document.billOfLading.consigneeCgwRecordId || ''];

      return {
        element: <File
          key={`${document.id}-bl`}
          id={document.billOfLading.id}
          fileData={document}
          documentId={document.id}
          documentType={document.documentType}
          info1={shipperDetail?.name || shipperDetail?.cgwCode}
          info2={consigneeDetail?.name || consigneeDetail?.cgwCode}
          activeDocumentId={props.activeDocument?.id}
          setSelected={() => props.setActiveDocument(document, true)}
          companyDocTypes={companyDocTypes}
          actionType={props.actionType}
          showDetails={showDetails(document.documentType,
            props.details?.emailAccount?.validationType, props.details?.postingBehaviour)}

          isDragDisabled={true}
          draggableId={document.id}
          draggableIndex={draggableIndex || 0}
          isDragging={isDragging}
          setIsDragging={setIsDragging}
          documentTypesOptions={documentTypesOptions}
          docTypesToUpdate={props.selectedTypeUpdates}
          setDocTypesToUpdate={props.setDocTypesToUpdate}
          selectable={!document.parentId}
          hasPostedConsol={hasPostedConsol}
          displaySelect={displaySelect}
          displayEndActions={displayEndActions}
          onSelect={onSelect}
          noDrag={noDrag}
        />
      }
    } else if (document.commercialInvoice) {
      const supplierDetail = props.issuerDetails[document.commercialInvoice.supplierRecordId || ''];
      const importerDetail = props.issuerDetails[document.commercialInvoice.importerRecordId || ''];

      return {
        element: <File
          key={`${document.id}-invoice`}
          id={document.commercialInvoice.id}
          fileData={document}
          documentId={document.id}
          documentType={DocumentType.CommercialInvoice}
          info1={supplierDetail?.name || supplierDetail?.cgwCode}
          info2={importerDetail?.name || importerDetail?.cgwCode}
          activeDocumentId={props.activeDocument?.id}
          setSelected={() => props.setActiveDocument(document, true)}
          companyDocTypes={companyDocTypes}
          actionType={props.actionType}
          showDetails={showDetails(document.documentType,
            props.details?.emailAccount?.validationType, props.details?.postingBehaviour)}
          isDragDisabled={props.isDragDisabled}
          draggableId={document.id}
          draggableIndex={draggableIndex || 0}
          isDragging={isDragging}
          setIsDragging={setIsDragging}
          documentTypesOptions={documentTypesOptions}
          docTypesToUpdate={props.selectedTypeUpdates}
          setDocTypesToUpdate={props.setDocTypesToUpdate}
          selectable={!document.parentId}
          hasPostedConsol={hasPostedConsol}
          displaySelect={displaySelect}
          displayEndActions={displayEndActions}
          onSelect={onSelect}
          noDrag={noDrag}
        />
      }
    } else if (props.actionType === ActionModalType.Hidden || document.documentType !== DocumentType.EmailBody) {
      return {
        element: <File
          key={`${document.id}-document`}
          id={document.id}
          fileData={document}
          documentId={document.id}
          documentType={document.documentType}
          activeDocumentId={props.activeDocument?.id}
          setSelected={() => props.setActiveDocument(document, true)}
          companyDocTypes={companyDocTypes}
          actionType={props.actionType}
          showDetails={showDetails(document.documentType,
            props.details?.emailAccount?.validationType, props.details?.postingBehaviour)}
          isDragDisabled={props.isDragDisabled}
          draggableId={document.id}
          draggableIndex={draggableIndex || 0}
          isDragging={isDragging}
          setIsDragging={setIsDragging}
          documentTypesOptions={documentTypesOptions}
          docTypesToUpdate={props.selectedTypeUpdates}
          setDocTypesToUpdate={props.setDocTypesToUpdate}
          selectable={!document.parentId}
          hasPostedConsol={hasPostedConsol}
          displaySelect={displaySelect}
          displayEndActions={displayEndActions}
          onSelect={onSelect}
          noDrag={noDrag}
        />
      };
    }
    return { element: <></> };
  }

  const buildJobTiles = (details?: APIDocumentGroupSearchDetails) => {
    setJobTiles([]);
    const jobs: JobTile[] = [];
    const consolJobFileElements: FileElement[] = [];
    const shipmentJobFileElements: FileElement[][] = [];
    const brokerageJobFileElements: FileElement[] = [];
    const consolJobFiles: APIDocumentContainer[] = [];
    const shipmentJobFiles: APIDocumentContainer[][] = [];
    const newSipmentJobFiles: APIDocumentContainer[][] = [];
    const brokerageJobFiles: APIDocumentContainer[] = [];

    let topFile = findTopFile(details?.documents.sort(sortByTypes) || props.details?.documents.sort(sortByTypes) || []);

    if (props.details?.postingBehaviour && fwdOrFncPostingBehaviour.includes(props.details.postingBehaviour) && !topFile) {
      topFile = props.details?.documents.find((d) => d.documentType === DocumentType.MasterBillOfLading ||
        props.details?.documents.find((d) => d.documentType === DocumentType.MasterBillOfLading));
    }
    const flatFiles = getFlatFiles(props.details?.documents || []);
    const usedFiles: number[] = [];
    const otherInHierarchy: number[] = [];

    if (props.details?.postingBehaviour && fwdOrFncPostingBehaviour.includes(props.details.postingBehaviour) && topFile) {
      const file = buildFileElement(topFile);
      usedFiles.push(topFile.id);
      let info1: string | undefined;
      let info2: string | undefined;
      let jobRef: string | undefined;
      let shipmentsInfo1: (string | undefined)[] = [];
      let shipmentsInfo2: (string | undefined)[] = [];
      let shipmentsJobRef: (string | undefined)[] = [];
      let phShipmentsJobRef: (string | undefined)[] = [];

      if (file && !placeholderTypes.includes(topFile.documentType))
        consolJobFiles.push(topFile);

      if ([DocumentType.PlaceholderConsol, DocumentType.MasterBillOfLading].includes(topFile.documentType) && topFile.billOfLading) {
        info1 = getIssuer(topFile.billOfLading.shipperCgwRecordId)?.label;
        info2 = getIssuer(topFile.billOfLading.consigneeCgwRecordId)?.label;
        if (topFile.billOfLading.importerReference?.length)
          jobRef = topFile.billOfLading.importerReference[0].jobRef;
      }

      if (topFile.children) {
        topFile.children.forEach((d) => {
          if ([DocumentType.HouseBillOfLading, DocumentType.PlaceholderShipment].includes(d.documentType)) {
            const files: APIDocumentContainer[] = [];
            usedFiles.push(d.id);
            if (!placeholderTypes.includes(d.documentType)) {
              files.push(d);

              if ([DocumentType.MasterBillOfLading, DocumentType.HouseBillOfLading, DocumentType.PlaceholderShipment].includes(d.documentType) && d.billOfLading) {
                shipmentsInfo1.push(getIssuer(d.billOfLading.shipperCgwRecordId)?.label);
                shipmentsInfo2.push(getIssuer(d.billOfLading.consigneeCgwRecordId)?.label);
                if (d.billOfLading.importerReference?.length)
                  shipmentsJobRef.push(d.billOfLading.importerReference[0].jobRef);
              }

              if (d.children) {
                d.children.forEach((d) => {
                  usedFiles.push(d.id);
                  if (!placeholderTypes.includes(d.documentType)) {
                    files.push(d);
                  }
                });
              }
              shipmentJobFiles.push(files);
            }
            if (placeholderTypes.includes(d.documentType)) {
              const files: APIDocumentContainer[] = [];
              files.push(d);
              usedFiles.push(d.id);
              if (d.children) {
                files.push(...d.children);
                usedFiles.push(...d.children.map((d) => d.id));
              }
              newSipmentJobFiles.unshift(files);
              if (DocumentType.PlaceholderShipment === d.documentType && d.billOfLading && d.billOfLading.importerReference?.length) {
                phShipmentsJobRef.unshift(d.billOfLading.importerReference[0].jobRef || '');
              }
            }
          } else if (!placeholderTypes.includes(d.documentType)) {
            others.push(d);
            otherInHierarchy.push(d.id);
          }
        });
      }

      if (others) {
        const otherFiles = others.filter((other) => !otherInHierarchy.find((used) => other.id === used));
        consolJobFiles.push(...otherFiles);
      }

      const extraFiles = flatFiles.filter((flat) => !usedFiles.find((used) => flat.id === used));

      if (extraFiles) {
        extraFiles.forEach((d) => {
          if (!placeholderTypes.includes(d.documentType)) {
            consolJobFiles.push(d);
          }
        });
      }

      const sortedConsolJobFiles = consolJobFiles.sort(sortByTypes);
      sortedConsolJobFiles.forEach((d, i) => {
        const fileElement = buildFileElement(d, i);
        consolJobFileElements.push({
          id: Number(fileElement.element.key?.toString().split('-')[0]),
          element: fileElement.element,
          type: d.documentType,
          documentId: d.id,
        });
      });

      jobs.push({
        files: consolJobFileElements, type: JobTileType.Consol, jobRef, info1, info2, topDocumentId: topFile.id
      });

      newSipmentJobFiles.forEach((documents, i) => {
        const files: FileElement[] = [];
        const sortedFiles = documents.sort(sortByTypes);
        let parentId = 0;
        sortedFiles.forEach((d, i) => {
          if (placeholderTypes.includes(d.documentType)) {
            parentId = d.id;
          } else {
            const fileElement = buildFileElement(d, i);
            files.push({
              id: Number(fileElement.element.key?.toString().split('-')[0]),
              element: fileElement.element,
              type: d.documentType,
              documentId: d.id,
            });
          }
        });
        jobs.push({
          files,
          type: JobTileType.Shipment,
          jobRef: phShipmentsJobRef[i],
          topDocumentId: parentId,
          isPlaceholder: true
        })
      });

      if (shipmentJobFiles.length > 0) {
        const sortedShipmentJobFiles = shipmentJobFiles
          .filter((f) => f.length > 0)
          .sort((a, b) => {
            return a[0].id - b[0].id;
          });
        sortedShipmentJobFiles.forEach((documents, i) => {
          const files: FileElement[] = [];
          const sortedFiles = documents.sort(sortByTypes);
          sortedFiles.forEach((d, i) => {
            const fileElement = buildFileElement(d, i);
            files.push({
              id: Number(fileElement.element.key?.toString().split('-')[0]),
              element: fileElement.element,
              type: d.documentType,
              documentId: d.id,
            });
          });
          jobs.push({
            files,
            type: JobTileType.Shipment,
            jobRef: shipmentsJobRef[i],
            info1: shipmentsInfo1[i],
            info2: shipmentsInfo2[i],
            topDocumentId: documents[0].id
          })
        });
      }

    } else if (props.details?.postingBehaviour &&
      [PostingBehaviour.NewBJob, PostingBehaviour.UpdateBJob].includes(props.details?.postingBehaviour) && topFile) {
      usedFiles.push(topFile.id);

      let info1;
      let info2;
      let jobRef;

      if ([DocumentType.HouseBillOfLading, DocumentType.MasterBillOfLading, DocumentType.PlaceholderBJob].includes(topFile.documentType) &&
        topFile.billOfLading) {
        if (props.details.postingBehaviour !== PostingBehaviour.UpdateBJob) {
          info1 = getIssuer(topFile.billOfLading.shipperCgwRecordId)?.label;
          info2 = getIssuer(topFile.billOfLading.consigneeCgwRecordId)?.label;
        }

        if (topFile.billOfLading.importerReference?.length)
          jobRef = topFile.billOfLading.importerReference[0].jobRef;
      }

      if (!placeholderTypes.includes(topFile.documentType))
        brokerageJobFiles.push(topFile);

      if (topFile.children) {
        topFile.children.forEach((d) => {
          usedFiles.push(d.id);
          brokerageJobFiles.push(d);

          if (d.children) {
            d.children.forEach((d) => {
              usedFiles.push(d.id);
              brokerageJobFiles.push(d);
            });
          }
        });
      }

      if (others) {
        brokerageJobFiles.push(...others);
      }

      const extraFiles = flatFiles.filter((flat) => !usedFiles.find((used) => flat.id === used));
      if (extraFiles) {
        extraFiles.forEach((d) => {
          if (!placeholderTypes.includes(d.documentType)) {
            brokerageJobFiles.push(d);
          }
        });
      }

      brokerageJobFiles.sort(sortByTypes).forEach((d, i) => {
        const fileElement = buildFileElement(d, i);
        brokerageJobFileElements.push({
          id: Number(fileElement.element.key?.toString().split('-')[0]),
          element: fileElement.element,
          type: d.documentType,
          documentId: d.id,
        });
      });

      jobs.push({
        files: brokerageJobFileElements, type: JobTileType.Brokerage, jobRef, info1, info2, topDocumentId: topFile.id
      });
    } else if (props.details?.postingBehaviour === PostingBehaviour.UpdateSJob && topFile) {
      const jobRefs: (string | undefined)[] = [];
      usedFiles.push(topFile.id);

      if (topFile.billOfLading) {
        if (topFile.billOfLading.importerReference?.length)
          jobRefs.push(topFile.billOfLading.importerReference[0].jobRef);
        else
          jobRefs.push(undefined);
      }

      shipmentJobFiles[0] = [];

      shipmentJobFileElements[0] = [];

      if (!placeholderTypes.includes(topFile.documentType))
        shipmentJobFiles[0].push(topFile);

      if (topFile.children) {
        topFile.children.forEach((d) => {
          usedFiles.push(d.id);
          if (!placeholderTypes.includes(d.documentType))
            shipmentJobFiles[0].push(d);

          if (d.children) {
            d.children.forEach((d) => {
              usedFiles.push(d.id);
              if (!placeholderTypes.includes(d.documentType))
                shipmentJobFiles[0].push(d);
            });
          }
        });
      }

      if (others) {
        shipmentJobFiles[0].push(...others);
      }

      const extraFiles = flatFiles.filter((flat) => !usedFiles.find((used) => flat.id === used));
      if (extraFiles) {
        extraFiles.forEach((d) => {
          if (!placeholderTypes.includes(d.documentType)) {
            shipmentJobFiles[0].push(d);
          }
        });
      }

      shipmentJobFiles.forEach((documents, i) => {
        const fileElements: FileElement[] = [];
        const sortedFiles = documents.sort(sortByTypes);
        sortedFiles.forEach((d, i) => {
          const fileElement = buildFileElement(d, i);
          fileElements.push({
            id: Number(fileElement.element.key?.toString().split('-')[0]),
            element: fileElement.element,
            type: d.documentType,
            documentId: d.id,
          });
        });

        jobs.push({
          files: fileElements,
          type: JobTileType.Shipment,
          jobRef: jobRefs[i],
          topDocumentId: documents[0].id
        });
      });
    } else if (props.details && (!topFile || [PostingBehaviour.StandaloneCInv, PostingBehaviour.WtgIntegration, PostingBehaviour.GenericTMS].includes(props.details?.postingBehaviour))) {
      const files = getFlatFiles(props.details.documents);
      const fileElements: FileElement[] = [];

      const sortedFiles = files.sort(sortByTypes);
      sortedFiles.forEach((d, i) => {
        if (!placeholderTypes.includes(d.documentType)) {
          const fileElement = buildFileElement(d, i)
          fileElements.push({
            id: Number(fileElement.element.key?.toString().split('-')[0]),
            element: fileElement.element,
            type: d.documentType,
            documentId: d.id,
          });
        }
      });

      jobs.push({
        files: fileElements, type: JobTileType.Undefined, topDocumentId: files[0].id
      });
    }

    setJobTiles(jobs);
  }

  React.useEffect(() => {
    buildJobTiles();
  }, [props.details, props.activeDocument, props.actionType, props.selectedTypeUpdates, props.newGroup, newGroupFiles]);

  React.useEffect(() => {
    if (props.details?.documents && props.details?.documents.length > 0) {
      const listOfNewGroupFiles: FileElement[] = [];
      props.newGroup.forEach((document) => {
        const flatDocs = getFlatFiles(props.details?.documents || []);
        const foundFile = flatDocs.find((elem) => elem.id === document.id);
        if (foundFile) {
          const built = buildFileElement(foundFile, undefined, true) as FileElement
          listOfNewGroupFiles.push({
            id: Number(built.element.key?.toString().split('-')[0]),
            element: built.element,
            type: built.type,
            documentId: built.documentId,
          });
        }
      })

      setNewGroupFiles(listOfNewGroupFiles);
    }
  }, [props.newGroup]);

  React.useEffect(() => {
    if (props.details?.documents.length) {
      const flatFiles = getFlatFiles(props.details.documents).sort(sortByInitialPriority);
      const firstCInv = flatFiles.find((document) => document.commercialInvoice);
      if (props.details.emailAccount?.validationType === ValidationType.ClearanceAndForwarding && firstCInv) {
        props.setActiveDocument(firstCInv, true);
      } else {
        props.setActiveDocument(flatFiles[0], true);
      }
    }
  }, [props.details?.id]);

  const onBeforeCapture = (before: BeforeCapture) => {
    setIsDragging(true)
    const findId = before.draggableId;
    let documentId: number = -1;
    jobTiles.forEach((tile) => {
      tile.files.forEach((file) => {
        if (file.documentId === parseInt(findId)) documentId = file.documentId;
      })
    })
    if (documentId !== -1) {
      const document = flatFiles.find((d) => d.id === documentId || d.email.id === documentId);
      props.setActiveDocument(document, true);
    }
  }

  const createNewPlaceholder = () => {
    if (props.details) {
      let topFile = findTopFile(props.details.documents.sort(sortByTypes) || []);
      const newPHShipment: APIDocumentContainer = {
        id: -(Date.now()),
        created: '',
        unqId: '',
        customId: '',
        companyId: props.details.documents[0].companyId || 0,
        documentType: DocumentType.PlaceholderShipment,
        filename: '',
        multidocFilename: '',
        sourceId: 0,
        groupId: props.details.documents[0]?.documentGroup?.id || 0,
        parentId: null,
        filePages: null,
        documentGroup: props.details.documents[0].documentGroup,
        emailId: props.details.documents[0].emailId,
        email: props.details.documents[0].email,
        emailAccount: props.details.documents[0].emailAccount,
        children: [],
        rotation: 0,
      }
      const detailsCopy = { ...props.details };
      if (detailsCopy?.documents && props.setDetails) {
        detailsCopy.documents.push(newPHShipment);
        const change = { documentId: topFile?.id || 0, childId: newPHShipment.id };
        const flatFilesUpdated = getFlatFiles(detailsCopy.documents);
        detailsCopy.documents = buildNewHierarchy(detailsCopy.documents, change, flatFilesUpdated);
        setFlatFiles(flatFilesUpdated);
        props.setDetails(detailsCopy);

        if (props.setHierarchyChanges) {
          props.setHierarchyChanges((hierarchyChanges) => [...hierarchyChanges, change]);
        }
      }
    }
  }

  const onClickRemoveShipment = (tileDocId: number) => {
    setDeleteShipment(tileDocId);
  }

  const removeShipment = () => {
    if (props.details && props.setDetails && deleteShipment) {
      const detailsCopy = { ...props.details };
      let topFile = findTopFile(detailsCopy.documents.sort(sortByTypes) || []);
      if (topFile) topFile.children = topFile.children.filter((f) => f.id !== deleteShipment);

      const changes: HierarchyChange[] = [];
      const tileToRemove = jobTiles.find((tile) => tile.topDocumentId === deleteShipment);
      if (tileToRemove && tileToRemove.files && topFile) {
        tileToRemove.files.forEach((file) => {
          if (deleteShipment !== file.id) {
            changes.push({ documentId: topFile!.id, childId: file.documentId });
          }
        })
      }
      const flatFilesUpdated = getFlatFiles(detailsCopy.documents);
      changes.forEach((change) => {
        if (detailsCopy.documents) detailsCopy.documents = buildNewHierarchy(detailsCopy.documents, change, flatFilesUpdated);
      });
      setFlatFiles(flatFilesUpdated);
      props.setDetails(detailsCopy);

      if (props.setHierarchyChanges) {
        props.setHierarchyChanges((hierarchyChanges) => [...hierarchyChanges, ...changes]);
      }
    }
    setDeleteShipment(null);
  }

  const tileConsol = jobTiles.find((tile) => tile.type === JobTileType.Consol)
  const modeClass = () => {
    let mode = '';
    if (props.editMode) mode += 'edit-mode';
    if (props.actionType === ActionModalType.SplitMode) mode += ' split-mode';
    if (props.actionType === ActionModalType.DragMode) mode += ' drag-mode';
    return mode;
  };

  const removeShipmentAndMoveDocs = (jobTile: JobTile) => {
    let message = '';
    const firstMessage = 'After splitting, this Shipment will no longer be available in Exception Manager.';
    if (jobTile.type !== JobTileType.Shipment) return message;
    if (newGroupFiles.length > 0 &&
      jobTile.files.filter((file) => !newGroupFiles.find((nFile) => nFile.id === file.id)).length === 0
    ) {
      message = firstMessage;
      // Had HBL but not anymore
    } else {
      // Has some HBL
      const foundFile = jobTile.files.find((f) => f.type === DocumentType.HouseBillOfLading);
      // But HBL was already moved to newGroupfiles
      if (foundFile && newGroupFiles.find((ngf) => ngf.id === foundFile.documentId)) {
        message = `${firstMessage}<br/>Documents associated with it will be moved to the Consol.`;
      }
    }
    return message;
  }

  return (
    <div className={`new-items-total-holder ${modeClass()} ${(flatFiles.length > 12 || jobTiles.length > 4) ? 'many-items' : ''} with-custom-scrollbar`}>
      <div className={`new-items-holder ${modeClass()}`} ref={newItemsHolderRef}>
        <DragDropContext onDragEnd={onDragEnd} onBeforeCapture={onBeforeCapture}>
          <div className="items width-height-draggable with-custom-scrollbar">
            {tileConsol && (
              <JobTile
                files={tileConsol.files.filter((file) => !newGroupFiles.find((nFile) => nFile.id === file.id))}
                type={tileConsol.type}
                jobRef={tileConsol.jobRef}
                info1={tileConsol.info1}
                info2={tileConsol.info2}
                draggableIndex={0}
                isDragging={isDragging}
                topDocumentId={tileConsol.topDocumentId}
                onClickRemoveShipment={() => { }}
                actionType={props.actionType}
                showWarning={removeShipmentAndMoveDocs(tileConsol)}
              />)}
            {(props.actionType === ActionModalType.DragMode && tileConsol) && (
              <AddItemBtn
                onClick={createNewPlaceholder}
                tooltipText={<><b>Add</b> new Shipment</>}
                alwaysVisible={true}
                disabled={false}
              />
            )}
            {jobTiles.filter((t) => t.type !== JobTileType.Consol).map((job, i) => <JobTile
              files={job.files.filter((file) => !newGroupFiles.find((nFile) => nFile.id === file.id))}
              type={job.type}
              jobRef={job.jobRef}
              info1={job.info1}
              info2={job.info2}
              draggableIndex={i + (tileConsol ? 1 : 0)}
              isDragging={isDragging}
              topDocumentId={job.topDocumentId}
              onClickRemoveShipment={onClickRemoveShipment}
              showWarning={removeShipmentAndMoveDocs(job)}
              actionType={props.actionType}
            />)}
            <div className={`items-actions ${props.displayActions ? 'show' : 'hide'}`}>
              <div className={`new-group ${Boolean(newGroupFiles.length) ? 'show' : ''}`}>
                <label>New Pack</label>
              </div>
            </div>
            <div className={`job-tile ${newGroupFiles.length <= 0 ? 'hide' : ''}`}>
              <div className="job-tile__body job-tile__body--no-header">
                <span className='hide'></span>
                {newGroupFiles.map((file) => file.element)}
              </div>
            </div>
          </div>
        </DragDropContext >
      </div>
      {!props.editMode && props.actionType !== ActionModalType.SplitMode && <div className="new-resizer-horizontal" onMouseDown={initDrag} title="Drag to resize"></div>}

      <ConfirmModal
        show={!!deleteShipment}
        title="Delete Shipment"
        text="This will remove the shipment and all its data. Documents belonging to the Shipment will be moved to the Consol.<br/><br/>
          <b>Are you sure you want to delete the Shipment?</b> Changes will only take affect when clicking ‘Save’."
        onConfirm={removeShipment}
        onHide={() => setDeleteShipment(null)}
        confirmText="Delete Shipment"
        cancelText="Cancel"
      />
    </div >
  )
})
