import * as React from 'react';
import { WrapperLabel } from '../../../common/WrapperLabel';
import { OptionValue } from 'react-selectize';
import { useHistory, useLocation, useParams } from 'react-router';
import { Link } from 'react-router-dom';
import { AdminHeader } from '../../AdminHeader';
import { Toggle } from '../../../common/Toggle';
import { APIEmailAlert, TeamAPI } from '../../../../api/teams';
import { UTCOffset, UTCOffsetLabel } from './index';
import { NumberInput } from '../../../common/NumberInput';
import { MultiEmailInput } from '../../../common/MultiEmailInput';
import { CustomSelect } from '../../../common/CustomSelect';
import { AdminLinks } from '../../Admin';
import { timezoneName } from './EmailAlertsTable';

interface UrlParams {
  id?: string;
  teamId?: string;
}

interface Props {
  checkboxValue?: boolean;
  checkboxLabel?: string;
  teamOptions: OptionValue[];
}

export const EmailAlertForm: React.FC<Props> = (props) => {
  const params = useParams<UrlParams>();
  const emailAlertId = params.id ? parseInt(params.id) : undefined;
  const teamId = params.teamId ? parseInt(params.teamId) : undefined;

  const [record, setRecord] = React.useState<Partial<APIEmailAlert>>({
    companyId: parseInt(localStorage.getItem('companyId') || ''),
    teamId,
  });

  const [formEntered, setFormEntered] = React.useState<boolean>(false);
  const submitDisabled = false;

  const history = useHistory();
  const location = useLocation<{ emailAlertData: Partial<APIEmailAlert> }>();

  React.useEffect(() => {
    if (emailAlertId) {
      TeamAPI.fetchEmailAlert(emailAlertId).then((data) => {
        setRecord(data);
      });
    }
  }, [params.id]);

  React.useEffect(() => {
    if (location.state?.emailAlertData) {
      setRecord((record) => ({
        ...(location.state.emailAlertData),
        id: record.id,
        teamId: record.teamId,
      }));
    }
  }, [location.state?.emailAlertData]);

  const onSave = async () => {
    setFormEntered(true);
    if (submitDisabled) return;

    if (emailAlertId) {
      await TeamAPI.updateEmailAlert(emailAlertId, record);
    } else {
      await TeamAPI.createEmailAlert(record);
    }

    history.push(AdminLinks.EmailAlerts);
  };

  const handleAlertTimeChange = (value: number | undefined) => {
    if (value === undefined || isNaN(value)) {
      setRecord({ ...record, alertTime: null });
      return;
    }

    let finalValue = value === 24 ? 0 : value;

    finalValue = finalValue - UTCOffset;

    if (finalValue < 0) {
      finalValue += 24;
    }

    setRecord({ ...record, alertTime: finalValue + ':00' })
  }

  const alertTimeHours = record.alertTime ? (parseInt(record.alertTime.split(':')[0]) + UTCOffset) % 24 : null

  return (
    <div className="admin__form__wrapper with-custom-scrollbar">
      <AdminHeader
        headline={params.id ? 'Edit email alert' : 'Add a new email alert'}
        tagText="Production workspace"
        links={[{
          url: '/knowledge-base/Setting-up-AP-Invoice-email-alerts---5eb6e04ae58942daa80d240fb3f3520a',
          label: 'How to configure email alerts'
        }]}
      />
      <div className="admin__form__content">
        <div>
          <WrapperLabel text="Team name">
            <CustomSelect
              onValueChange={() => {}}
              options={props.teamOptions}
              value={props.teamOptions.find((option) => option.value === record.teamId)}
              disabled
            />
          </WrapperLabel>
          <WrapperLabel text="User alert">
            <Toggle
              id="userAlert"
              checked={!!record.alertUser}
              onChecked={() => setRecord({ ...record, alertUser: !record.alertUser })}
            />
          </WrapperLabel>
          <WrapperLabel text="Manager alert">
            <Toggle
              id="managerAlert"
              checked={!!record.alertManager}
              onChecked={() => setRecord({ ...record, alertManager: !record.alertManager })}
            />
          </WrapperLabel>
          {(record.alertUser || record.alertManager) && (
            <WrapperLabel text="User threshold">
              <NumberInput
                value={record.userLimit || null}
                name="userLimit"
                onChange={(value) => value !== undefined && setRecord({ ...record, userLimit: value })}
                precision={0}
                min={1}
                required
                showWarningIcon
                className="email-alerts-section__form__user-limit"
              />
            </WrapperLabel>
          )}
          {record.alertManager && (
            <WrapperLabel text="Team threshold">
              <NumberInput
                value={record.teamLimit || null}
                name="teamLimit"
                onChange={(value) => value !== undefined && setRecord({ ...record, teamLimit: value })}
                precision={0}
                min={1}
                required
                showWarningIcon
                className="email-alerts-section__form__team-limit"
              />
            </WrapperLabel>
          )}
          <WrapperLabel text="Manager emails">
            <MultiEmailInput
              values={record.managerEmails || []}
              setValues={(values) => setRecord({ ...record, managerEmails: values })}
              placeholder="Add emails and hit enter..."
            />
          </WrapperLabel>
          <WrapperLabel text="Alert time">
            <NumberInput
              value={alertTimeHours}
              name="alertTimeHours"
              onChange={handleAlertTimeChange}
              precision={0}
              minimumIntegerDigits={2}
              min={0}
              max={24}
              required
              showWarningIcon
              suffix={`:00${UTCOffsetLabel ? ' UTC' + UTCOffsetLabel : ''} (${timezoneName})`}
            />
          </WrapperLabel>
        </div>
        <div>
          <Link to={AdminLinks.EmailAlerts}><button className="light-button">Cancel</button></Link>
          <button className="full-button" onClick={onSave}>Save</button>
        </div>
      </div>
    </div>
  );
}
