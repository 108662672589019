import { request } from './request';
import { APIEmail, APISupplierInvoice } from './supplierInvoice';
import { APIDocumentGroup, APIDocumentGroupCInvoice, APIDocumentGroupConsol, APIDocumentGroupPackingList } from './documentGroup';
import { APIEmailAccount } from './emailAccount';
import { tableDefinitions } from '../components/common/document-viewer/TableHighlight';

export interface FieldData {
  objectIds: number[];
  tableName: string;
  attributeNames: string[];
}

export interface APIDocCoordinate {
  id: number;
  documentId: number;
  parentTableName: string;
  parentTableId: number;
  attributeName: string;
  pageIndex: number;
  xLeft: number;
  xRight: number;
  yTop: number;
  yBottom: number;

  isHidden?: boolean;
}

export interface APITableDefinitions {
  [index: number]: {
    x: {
      type: string | null,
      bounds: number[],
    }[],
    y: {
      hasData: boolean,
      bounds: number[],
    }[],
  }[]
}

export interface APIStatisticsTableCoordinates {
  documentId: number;
  actionType: 'opened' | 'previewed' | 'applied';
}

export interface CoordinatesFilter extends Partial<APIDocCoordinate> {
  parentTableIds?: number[];
  attributeNames?: string[];
}

export const filterCoordinates = (allCoordinates: { [id: string]: APIDocCoordinate[] }, coordinatesFilter: CoordinatesFilter) => {
  if (allCoordinates && Object.keys(allCoordinates).length) {
    const allCoordinatesCopy = { ...allCoordinates };
    Object.keys(allCoordinates).forEach((documentId) => {
      allCoordinatesCopy[documentId] = allCoordinates[documentId].filter((coordinate) => {
        let shouldRemove = true;
        // @ts-ignore
        Object.keys(coordinatesFilter).forEach((key: keyof CoordinatesFilter) => {
          if (key === 'parentTableIds') {
            if (!coordinatesFilter.parentTableIds?.includes(coordinate.parentTableId)) {
              shouldRemove = false;
            }
          } else if (key === 'attributeNames') {
            if (!coordinatesFilter.attributeNames?.includes(coordinate.attributeName)) {
              shouldRemove = false;
            }
          } else if (coordinate[key] !== coordinatesFilter[key]) {
            shouldRemove = false;
          }
        });
        return !shouldRemove;
      });
    });

    return allCoordinatesCopy;
  }

  return {};
}

export enum DocumentType {
  Undefined = 0,
  BillOfLading = 1,
  ArrivalNotice = 2,
  DeliveryOrder = 3,
  SupplierInvoice = 4,
  CommercialInvoice = 5,
  MasterBillOfLading = 6,
  HouseBillOfLading = 7,
  PackingList = 8,
  PackingDeclaration = 9,
  CertificateOfOrigin = 10,
  OverheadInvoice = 11,
  PlaceholderConsol = 12,
  PlaceholderShipment = 13,
  PlaceholderBJob = 14,
  VendorQuote = 15,
  ISFDocument = 16,
  ProofOfDelivery = 17,
  HealthCertificate = 18,
  ManufacturesDeclaration = 19,
  ForwardersCargoReceipt = 20,
  VendorPaidOriginChargeSummary = 21,
  AgentsInvoice = 22,
  InterimFootwearForm = 23,
  FirstSaleInvoice = 24,
  GeneralConformityCertificate = 25,
  VendorInvoiceApproval = 26,
  MiscellaneousCustomsDocument = 27,
  WharfGateIn = 28,

  EmailBody = 900,
  MultiDoc = 1000,

  UKN = 77777,
  EML = 88888,
}

export const blTypes = [DocumentType.MasterBillOfLading, DocumentType.HouseBillOfLading];
export const unsupportedReparseTypes = [
  DocumentType.PackingDeclaration,
  DocumentType.CertificateOfOrigin,
  DocumentType.Undefined,
  DocumentType.ArrivalNotice,
  DocumentType.DeliveryOrder,
  DocumentType.VendorQuote,
  DocumentType.ProofOfDelivery,
  DocumentType.ISFDocument,
  DocumentType.HealthCertificate,
  DocumentType.ManufacturesDeclaration,
  DocumentType.ForwardersCargoReceipt,
  DocumentType.VendorPaidOriginChargeSummary,
  DocumentType.AgentsInvoice,
  DocumentType.InterimFootwearForm,
  DocumentType.FirstSaleInvoice,
  DocumentType.GeneralConformityCertificate,
  DocumentType.VendorInvoiceApproval,
  DocumentType.MiscellaneousCustomsDocument,
  DocumentType.WharfGateIn,
  DocumentType.EmailBody,
];

export interface MinimalHierarchy {
  id?: number,
  documentType?: DocumentType,
  children?: MinimalHierarchy[]
}

export interface APIDocumentContainer {
  id: number;
  created: string;
  unqId: string;
  customId: string;
  emailId?: number;
  companyId: number;
  documentType: DocumentType;
  documentAlias?: string;
  filename: string;
  multidocFilename: string | null;
  sourceId: number;
  groupId: number;
  parentId: number | null;
  filePages: number[] | null;
  rotation: number;

  parent?: APIDocumentContainer;

  supplierInvoice?: APISupplierInvoice;
  billOfLading?: APIDocumentGroupConsol;
  commercialInvoice?: APIDocumentGroupCInvoice;
  packingList?: APIDocumentGroupPackingList;
  documentGroup: APIDocumentGroup;
  email: APIEmail;
  emailAccount: APIEmailAccount;
  children: APIDocumentContainer[];
}

export interface APIDocumentType {
  id: number;
  name: string;
  displayName?: string;
  originalCwCode: string;
  cwCode?: string;
}

export interface APIDocumentReclassify {
  id: number;
  newDocumentType?: number;
}

export interface APIDisassociateDocumentId {
  documentId: null;
}

export interface APISplitDetails {
  id?: number;
  pages: number[] | null;
  type: DocumentType;
  alias?: string;
  unqId?: string; /* Parameter added to investigate error SHIP-7874 & SHIP-8317 */
}

export const DocumentContainerAPI = {
  async fetchDocument(documentId: number): Promise<any> {
    return request(`/DocumentContainers/getDocument?documentId=${encodeURIComponent(documentId)}`);
  },

  async fetchDocumentContainer(documentId: number): Promise<any> {
    return request(`/DocumentContainers/${documentId}`);
  },

  async fetchCoordinates(documentId: number): Promise<APIDocCoordinate[]> {
    const coordinates = await request(`/DocumentContainers/${documentId}/coordinates`, 'POST');

    const uniqueCoordinatesMap = coordinates.reduce((result: {}, coordinate: APIDocCoordinate) => ({
      ...result,
      [coordinate.attributeName + '-' + coordinate.parentTableId + '-' + coordinate.parentTableName]: coordinate
    }), {});

    return orderCoordinatesByAreaSize(Object.values(uniqueCoordinatesMap));
  },

  async removeCoordinates(coordinatesFilter: CoordinatesFilter): Promise<any> {
    if (!coordinatesFilter.parentTableId && !coordinatesFilter.parentTableIds?.length) return;

    const filter = {
      ...coordinatesFilter,
      ...(coordinatesFilter.parentTableIds?.length && { parentTableId: { inq: coordinatesFilter.parentTableIds } }),
      ...(coordinatesFilter.attributeNames?.length && { attributeName: { inq: coordinatesFilter.attributeNames } }),
    };
    delete filter.parentTableIds;
    delete filter.attributeNames;

    return request(`/DocCoordinates?where=${encodeURIComponent(JSON.stringify(filter))}`, 'DELETE');
  },

  async fetchDocumentTypes(): Promise<APIDocumentType[]> {
    return request(`/DocumentTypes/get`);
  },

  async update(id: number, change: Partial<APIDocumentContainer>): Promise<unknown> {
    return request(`/DocumentContainers/${id}`, 'PATCH', change);
  },

  async reclassify(documents: APIDocumentReclassify[]): Promise<unknown> {
    return request(`/DocumentContainers/reparseDocument`, 'POST', { documentsToParse: documents }, undefined, true);
  },

  async discardDocument(documentId: number): Promise<unknown> {
    return request(`/DocumentContainers/${documentId}`, 'PATCH', { documentStatus: -1 });
  },

  async rotateDocument(documentId: number, degrees: number): Promise<unknown> {
    return request(`/DocumentContainers/${documentId}`, 'PATCH', { rotation: degrees });
  },

  async discardDocumentArrivedInAPInvoiceMailbox(documentId: number): Promise<unknown> {
    return request(`/DocumentContainers/${documentId}/discardNotAnInvoice`, 'POST');
  },

  async search(filter: any): Promise<APIDocumentContainer[]> {
    return request(`/DocumentContainers?filter=${encodeURIComponent(JSON.stringify(filter))}`);
  },

  async splitAndUpdateDocument(parentId: number, data: APISplitDetails[]): Promise<unknown> {
    return request(`/DocumentContainers/${parentId}/splitAndUpdateDocument`, 'POST', { data });
  },

  async deleteFileHierarchy(id: number): Promise<unknown> {
    return request(`/DocumentContainers/${id}/destroyHierarchy`, 'DELETE');
  },

  async makeHierarchyChild(id: number, parentId: number): Promise<unknown> {
    return request(`/DocumentContainers/${id}/makeHierarchyChild`, 'POST', { parentId });
  },

  async updateTableCoordinates(documentId: number, data: APITableDefinitions, type: string): Promise<unknown> {
    return request(`/DocumentContainers/${documentId}/updateTableCoordinates?type=${type}`, 'POST', data);
  },

  async preLoadTableCoordinatesCache(documentId: number): Promise<unknown> {
    return request(`/DocumentContainers/${documentId}/preLoadTableCoordinatesCache`, 'POST');
  },

  async registerTableCoordinatesStatistics(values: Partial<APIStatisticsTableCoordinates>): Promise<APIStatisticsTableCoordinates> {
    return request(`/StatisticsTableCoordinates`, 'POST', values);
  },
}

export const findMatchedCoordinates = (fieldData: FieldData | null, coordinates: APIDocCoordinate[]): APIDocCoordinate[] => {
  if (!fieldData || !coordinates) {
    return [];
  }

  return coordinates.filter((coordinate) => {
    if (!fieldData.attributeNames.includes(coordinate.attributeName)) {
      return false;
    }

    if (coordinate.parentTableName !== fieldData.tableName) {
      return false;
    }

    return fieldData.objectIds.includes(coordinate.parentTableId);
  });
}

const calculateAreaSize = (coordinate: APIDocCoordinate): number => {
  return (coordinate.xRight - coordinate.xLeft) * (coordinate.yTop - coordinate.yBottom);
}

/*
  we need to render the boxes in a way where the bigger ones are rendered first. So if there is a smaller box completely
  overlapped by a big one, it's still clickable
 */
export const orderCoordinatesByAreaSize = (coordinates: APIDocCoordinate[]): APIDocCoordinate[] => {
  return coordinates.sort((coordinate1, coordinate2) => calculateAreaSize(coordinate2) - calculateAreaSize(coordinate1));
}

const OVERLAP_THRESHOLD = 0.001;

const areValuesSimilarWithinTheThreshold = (value1: number, value2: number): boolean => Math.abs(value1 - value2) < OVERLAP_THRESHOLD;

export const haveCoordinatesFullOverlap = (coordinate1: APIDocCoordinate, coordinate2: APIDocCoordinate) => {
  return areValuesSimilarWithinTheThreshold(coordinate1.xLeft, coordinate2.xLeft) &&
    areValuesSimilarWithinTheThreshold(coordinate1.xRight, coordinate2.xRight) &&
    areValuesSimilarWithinTheThreshold(coordinate1.yTop, coordinate2.yTop) &&
    areValuesSimilarWithinTheThreshold(coordinate1.yBottom, coordinate2.yBottom) &&
    coordinate1.pageIndex === coordinate2.pageIndex;
}
